<template>
<div>
    <h1>Mobile Home</h1>
</div>
</template>

<script>
export default {
  name: 'MobileIndex',
  created() {}
}
</script>

<style lang="less" scoped>
@import (reference) "~mobile-base.less";
</style>
